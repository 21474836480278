import React from 'react';

import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/homebuilding.css';

loadCSS('homebuilding-site');

export const HomebuildingSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default HomebuildingSiteWrapper;
